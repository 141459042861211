import React from "react"
import { LocationsContainer, LocationInfo } from "./style"
import useMedia from "hooks/useMedia.js"
import { GetLocationsData } from "utils/locationsData"
const Locations = () => {
  const locationsData = GetLocationsData()

  const isMobile = useMedia(
    // Media queries
    ["(max-width: 767px)"],
    //options
    [true],
    // default
    false
  )

  return (
    <LocationsContainer>
      {locationsData.map((location, index) => {
        return (
          <LocationInfo key={index}>
            <h4>{location.title}</h4>
            <p
              className="address"
              dangerouslySetInnerHTML={{
                __html: location.address,
              }}
            />
            {/* <span className="name">{location.person}</span> */}
            {/* <div className="number">
          <span>M</span>
          {isMobile ? (
            <a href={`tel:${location.mobile}`}>{location.mobile}</a>
          ) : (
            <p>{location.mobile}</p>
          )}
        </div> */}

            <div className="number">
              <span>P</span>
              {isMobile ? (
                <a href={`tel:${location.telephone}`}>{location.mobile}</a>
              ) : (
                <p>{location.mobile}</p>
              )}
            </div>
            <a className="email" href={`mailto:${location.email}`}>
              Email us
            </a>
          </LocationInfo>
        )
      })}
    </LocationsContainer>
  )
}

export default Locations
